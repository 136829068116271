var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"popregular medium",staticStyle:{"text-align":"justify","font-size":"14px"},attrs:{"xs12":"","pa-4":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[(!_vm.showMore)?_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-weight":"bold","font-size":"17px"}},[_vm._v(" ALLOPARENTING HORNBILLS ")]),_c('span',{staticStyle:{"float":"right","font-weight":"900"}},[_c('a',{staticStyle:{"color":"blue","text-decoration":"underline"},attrs:{"href":"#"},on:{"click":function($event){return _vm.toggleReadMore($event)}}},[_c('v-img',{attrs:{"height":"100%","width":"auto","src":require("../assets/Images/icons8-plus-30.png")}})],1)])]):_c('v-flex',[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" ALLOPARENTING HORNBILLS ")]),_c('span',{staticStyle:{"float":"right","font-weight":"900"}},[_c('a',{staticStyle:{"color":"blue","text-decoration":"underline"},attrs:{"href":"#"},on:{"click":function($event){return _vm.toggleReadMore($event)}}},[_c('v-img',{attrs:{"height":"100%","width":"auto","src":require("../assets/Images/icons8-minus-30.png")}})],1)]),_c('p',[_vm._v(" In 2022, we rescued an entire family of oriental pied hornbills in Assam, prompted by the father hornbill’s drowning. At CWRC, we provided enriching experiences and artificial nests, nurturing the mother and three nestlings. After a month, the nestlings matured into fledglings and started flying with their mother."),_c('br'),_vm._v(" We soft-released them in our CWRC habitat, where a wild male hornbill took on the role of the father and joined the family! This incredible behaviour is called ‘Alloparenting’. ")]),_c('iframe',{attrs:{"height":_vm.$vuetify.breakpoint.name == 'xs'
 ? '200px'
 : _vm.$vuetify.breakpoint.name == 'sm'
 ? '350px'
 : _vm.$vuetify.breakpoint.name == 'md'
 ? '300px'
 : _vm.$vuetify.breakpoint.name == 'xl'
 ? '400px'
 : _vm.$vuetify.breakpoint.name == 'lg'
 ? '350px'
 : '350px',"width":"100%","src":"https://www.youtube.com/embed/u_e1m5akeG4","frameborder":"1","allowfullscreen":"","ng-show":"showvideo"}})]),_c('v-flex',{attrs:{"xs12":""}})],1)],1),_c('span')],1)}
var staticRenderFns = []

export { render, staticRenderFns }