var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"popregular medium",staticStyle:{"text-align":"justify","font-size":"14px"},attrs:{"xs12":"","pa-4":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[(!_vm.showMore)?_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-weight":"bold","font-size":"17px"}},[_vm._v(" BEAR CUBS: RAMA, ANUSHKA, GUENG ")]),_c('span',{staticStyle:{"float":"right","font-weight":"900"}},[_c('a',{staticStyle:{"color":"blue","text-decoration":"underline"},attrs:{"href":"#"},on:{"click":function($event){return _vm.toggleReadMore($event)}}},[_c('v-img',{attrs:{"height":"100%","width":"auto","src":require("../assets/Images/icons8-plus-30.png")}})],1)])]):_c('v-flex',[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" BEAR CUBS: RAMA, ANUSHKA, GUENG ")]),_c('span',{staticStyle:{"float":"right","font-weight":"900"}},[_c('a',{staticStyle:{"color":"blue","text-decoration":"underline"},attrs:{"href":"#"},on:{"click":function($event){return _vm.toggleReadMore($event)}}},[_c('v-img',{attrs:{"height":"100%","width":"auto","src":require("../assets/Images/icons8-minus-30.png")}})],1)]),_c('br'),_c('p',[_vm._v(" The three bear cubs were barely two days old (the youngest admissions so far) when they came to CBRC in Arunachal Pradesh. They were admitted as orphans as their mothers were probably poached for their gallbladders. Nearly 70 such orphaned bear cubs have been successfully released back into the wild by our centre - CBRC in Arunachal Pradesh. ")]),_c('iframe',{attrs:{"height":_vm.$vuetify.breakpoint.name == 'xs'
 ? '200px'
 : _vm.$vuetify.breakpoint.name == 'sm'
 ? '350px'
 : _vm.$vuetify.breakpoint.name == 'md'
 ? '300px'
 : _vm.$vuetify.breakpoint.name == 'xl'
 ? '400px'
 : _vm.$vuetify.breakpoint.name == 'lg'
 ? '350px'
 : '350px',"width":"100%","src":"https://www.youtube.com/embed/MmMlgKTM6Aw","frameborder":"1","allowfullscreen":"","ng-show":"showvideo"}})]),_c('v-flex',{attrs:{"xs12":""}})],1)],1),_c('span')],1)}
var staticRenderFns = []

export { render, staticRenderFns }