var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"popregular medium",staticStyle:{"font-size":"14px"},attrs:{"xs12":"","pa-4":""}},[_c('v-layout',{attrs:{"wrap":""}},[(!_vm.showMore)?_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-weight":"bold","font-size":"17px"}},[_vm._v(" GANGA THE RHINO ")]),_c('span',{staticStyle:{"float":"right","font-weight":"900"}},[_c('a',{staticStyle:{"color":"blue","text-decoration":"underline"},attrs:{"href":"#"},on:{"click":function($event){return _vm.toggleReadMore($event)}}},[_c('v-img',{attrs:{"height":"100%","width":"auto","src":require("../assets/Images/icons8-plus-30.png")}})],1)])]):_c('v-flex',[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" GANGA THE RHINO")]),_c('span',{staticStyle:{"float":"right","font-weight":"900"}},[_c('a',{staticStyle:{"color":"blue","text-decoration":"underline"},attrs:{"href":"#"},on:{"click":function($event){return _vm.toggleReadMore($event)}}},[_c('v-img',{attrs:{"height":"100%","width":"auto","src":require("../assets/Images/icons8-minus-30.png")}})],1)]),_c('p',[_vm._v(" In 2004, we rescued 'Ganga,' a rhino calf at just 2 months old. After 4 years of care at CWRC in Assam, she was released into the wild in November 2008. Ganga adapted well, becoming a mother in April 2012, and her legacy continued with three more calves born in 2015, 2019, and 2021. The success of her lineage in the wild highlights the impact of our rhino rehabilitation efforts. Ganga's rescue was vital for her survival. ")]),_c('v-img',{staticClass:"hidden-sm-and-down",attrs:{"height":"350px","width":"auto","contin":"","src":require("../assets/Images/gangarhino.jpeg")}}),_c('v-img',{staticClass:"hidden-md-and-up",attrs:{"height":_vm.$vuetify.breakpoint.name == 'xs'
         ? '200px'
         : _vm.$vuetify.breakpoint.name == 'sm'
         ? '350px'
         : _vm.$vuetify.breakpoint.name == 'md'
         ? '250px'
         : _vm.$vuetify.breakpoint.name == 'xl'
         ? '500px'
         : _vm.$vuetify.breakpoint.name == 'lg'
         ? '320px'
         : '320px',"width":"100%","src":require("../assets/Images/gangarhino.jpeg")}})],1),_c('v-flex',{attrs:{"xs12":""}})],1)],1),_c('span')],1)}
var staticRenderFns = []

export { render, staticRenderFns }