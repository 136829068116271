<template>
  <v-flex xs12 pa-4 style="font-size: 14px;" class="popregular medium">

    <v-layout wrap>
      <v-flex xs12 v-if="!showMore">
          <span style="font-weight:bold;font-size: 17px;">
            GANGA THE RHINO
          </span>
          <span style="float: right;font-weight: 900;">
          <!-- In 2004, we rescued &#39;Ganga,&#39; a rhino calf at just 2 months old. After 4 years of care at CWRC in
          Assam, she was released into the wild in November 2008. Ganga adapted well, -->
          <a href="#" @click="toggleReadMore($event)" style="color: blue; text-decoration: underline;" > <v-img height="100%" width="auto" src="../assets/Images/icons8-plus-30.png"></v-img></a>
        </span>
      </v-flex>
      <v-flex v-else>
        <v-flex xs12>
          <span style="font-weight:bold">
            GANGA THE RHINO</span>
            <span style="float: right;font-weight: 900;">
              <a href="#" @click="toggleReadMore($event)" style="color: blue; text-decoration: underline;"> <v-img height="100%" width="auto" src="../assets/Images/icons8-minus-30.png"></v-img></a>
            </span>
            <p>
            In 2004, we rescued &#39;Ganga,&#39; a rhino calf at just 2 months old. After 4 years of care at CWRC in
            Assam, she was released into the wild
            in November 2008. Ganga adapted well, becoming a mother in April 2012, and her legacy continued with three
            more calves born in 2015, 2019, and 2021.
            The success of her lineage in the wild highlights the impact of our rhino rehabilitation efforts.
            Ganga&#39;s rescue was vital for her survival.
            </p>
          <v-img class="hidden-sm-and-down" height="350px" width="auto" contin src="../assets/Images/gangarhino.jpeg"></v-img>
          <v-img class="hidden-md-and-up"  
          :height="
         $vuetify.breakpoint.name == 'xs'
           ? '200px'
           : $vuetify.breakpoint.name == 'sm'
           ? '350px'
           : $vuetify.breakpoint.name == 'md'
           ? '250px'
           : $vuetify.breakpoint.name == 'xl'
           ? '500px'
           : $vuetify.breakpoint.name == 'lg'
           ? '320px'
           : '320px'
       "
          
          width="100%"  src="../assets/Images/gangarhino.jpeg"></v-img>
      
        </v-flex>



        <v-flex xs12>

        </v-flex>

      </v-flex>
    </v-layout>
    <span>



    </span>
  </v-flex>
</template>

<script>
  export default {
    data() {
      return {
        showMore: false,
      };
    },
    methods: {
      toggleReadMore(event) {
        event.preventDefault();
        this.showMore = !this.showMore;
      },
    },
  };
</script>