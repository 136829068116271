<template>
    <v-flex xs12 pa-4 style="text-align: justify;font-size: 14px;" class="popregular medium">

        <v-layout wrap justify-center>
            <v-flex xs12 v-if="!showMore">
           
                    <span style="font-weight:bold;font-size: 17px;">
                        BEAR CUBS: RAMA, ANUSHKA, GUENG
                    </span>
                    <span style="float: right;font-weight: 900;">
                    <!-- In 2004, we rescued &#39;Ganga,&#39; a rhino calf at just 2 months old. After 4 years of care at CWRC in
                    Assam, she was released into the wild in November 2008. Ganga adapted well, -->
                    <a href="#" @click="toggleReadMore($event)" style="color: blue; text-decoration: underline;" > <v-img height="100%" width="auto" src="../assets/Images/icons8-plus-30.png"></v-img></a>
                  </span>
            </v-flex>
            <v-flex v-else>
                <v-flex xs12>
                    <span style="font-weight:bold">
                        BEAR CUBS: RAMA, ANUSHKA, GUENG
                    </span>
                    <span style="float: right;font-weight: 900;">
                      <a href="#" @click="toggleReadMore($event)" style="color: blue; text-decoration: underline;"> <v-img height="100%" width="auto" src="../assets/Images/icons8-minus-30.png"></v-img></a>
                    </span><br>
                    <p>
                        The three bear cubs were barely two days old (the youngest admissions so far) when they came to
                        CBRC in Arunachal Pradesh. They were admitted as orphans as their mothers were probably poached for
                        their gallbladders. Nearly 70 such orphaned bear cubs have been successfully released back into the wild by our centre - CBRC in Arunachal Pradesh.
                    </p>
                    <iframe
                    :height="
$vuetify.breakpoint.name == 'xs'
 ? '200px'
 : $vuetify.breakpoint.name == 'sm'
 ? '350px'
 : $vuetify.breakpoint.name == 'md'
 ? '300px'
 : $vuetify.breakpoint.name == 'xl'
 ? '400px'
 : $vuetify.breakpoint.name == 'lg'
 ? '350px'
 : '350px'
"
      width="100%"

       src="https://www.youtube.com/embed/MmMlgKTM6Aw"
       frameborder="1"
       allowfullscreen
       ng-show="showvideo"
     ></iframe>
                 
                </v-flex>



                <v-flex xs12>

                </v-flex>

            </v-flex>
        </v-layout>
        <span>



        </span>
    </v-flex>
</template>

<script>
    export default {
        data() {
            return {
                showMore: false,
            };
        },
        methods: {
            toggleReadMore(event) {
                event.preventDefault();
                this.showMore = !this.showMore;
            },
        },
    };
</script>